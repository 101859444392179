/*====================  
    6.1 Button CSS  
====================*/

.btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    font-family: $headings-font-family;
    justify-content: center;
    padding: 15px 30px;

    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    outline: none;
    border: 0;

    // Responsive
    @media #{$large-mobile}{
        font-size: 14px;
        padding: 15px 20px;
    }
    &:focus {
        box-shadow: none !important;
    }

    &-icon {
        width: 56px;
        padding: 15px;
    }

    &-sm {
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        padding: 9px 10px;
        border-radius: 5px;
        border: 0;
    }

    &.disabled {
        opacity: 0.65;
    }
}
/* Button Color & Style (Solid & Outline) */
@each $name,
$value in $theme-colors {
    .btn-#{$name} {
        border-color: $value;
        background-color: $value;

        @if $name==warning or $name==light {
            color: $primary;
        }

        @else if $name==white-gray {
            color: $primary;
        }

        @else {
            color: $white;
        }

        &:hover,
        &:focus {
            border-color: transparent;
            background-color: darken($value, 7.5%);

            @if $name==warning or $name==light {
                color: $headings-color;
            }

            @else {
                color: $white;
            }
        }
    }

    .btn-outline-#{$name} {
        color: $value;
        border-color: $value;
        border-width: $border-width;
        border-style: solid;
        background-color: transparent;

        &:hover {
            border-color: transparent;
            background-color: $value;

            @if $name==warning or $name==light {
                color: $headings-color;
            }

            @else if $name==white-opacity-50 {
                color: $white;
            }

            @else {
                color: $white;
            }
        }
    }
}

/* Button Hover Color & Style (Solid & Outline) */
@each $name,
$value in $theme-colors {
    .btn-hover-#{$name} {
        &:hover,
        &:not(:disabled):not(.disabled).active {
            border-color: transparent;
            background-color: $value;

            @if $name==warning or $name==light {
                color: $headings-color;
            }

            @else {
                color: $white;
            }
        }
    }

    .btn-outline-hover-#{$name} {
        &:hover,
        &:not(:disabled):not(.disabled).active {
            color: $value;
            border-color: transparent;
            background-color: transparent;
        }
    }
}