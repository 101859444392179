/*====================  
    3.2 Header Bottom CSS
====================*/
.header-bottom {
    background-color: transparent;
    min-height: 100px;
}
/* Main Menu */
.main-menu {
    display: flex;
    justify-content: center;
    & > ul {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 0;
        padding-left: 0;

        list-style: none;
        & > li {
            & + li {
                margin-left: 55px;
                @media #{$laptop-device} {
                    margin-left: 45px;
                }
            }
            &:hover {

                & > a {
                    & > span {

                        &:before {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                    }
                }

            }
            & > a {
                font-size: 15px;
                line-height: 100px;

                text-decoration: none;
                letter-spacing: 0.5px;

                color: $dark;
                font-weight: 500;
                font-family: $headings-font-family;
                &:hover,
                &.active {
                    color: $primary;
                    & > span {

                        &:before {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                    }
                }

                & > span {
                    position: relative;
                    
                    &:before {
                        background-color: $primary;
                        content: "";
                        position: absolute;
                        left: auto;
                        right: 0;
                        bottom: -2px;
                        height: 2px;
                        transition: 0.4s;
                        width: 0;
                    }
                }
            }
        }
    }

    & ul {
        & .has-children {
            position: relative;
            & .sub-menu {
                position: absolute;
                background: $white;
                width: 230px;
                padding: 20px 20px 24px;
                transform-origin: 0 0 0;
                transform: scaleY(0);
                box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease-in-out;
                z-index: 9;
                border-bottom: 3px solid $primary;

                li {
                    list-style: none;
                    
                    a {
                        line-height: 40px;
                        color: $dark;
                        text-decoration: none;
                        font-size: 14px;
                        font-family: $headings-font-family;
                        font-weight: 400;

                        &:hover {
                            color: $primary;
                            padding-left: 5px;
                        }
                    }
                }
            }

            &:hover {
                & .sub-menu, .mega-menu {
                    transform: scaleY(1);
                }
            }
        }
    }
}

/* Mega Menu */
.mega-menu {
    background: $white;
    padding: 30px;
    transition: all 0.3s ease-in-out;
    transform-origin: 0 0 0;
    transform: scaleY(0);
    position: absolute;
    width: calc(100% + 185px);
    left: 15px;
    right: auto;
    top: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 99;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 3px solid $primary;
    & > li {
        list-style: none;

        & .mega-menu-title {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 15px;
            position: relative;
            font-family: $headings-font-family;

            &::after {
                position: absolute;
                content: "";
                background: $primary;
                bottom: 0;
                left: 0;
                width: 50px;
                height: 2px;
            }
        }

       & > ul {
            padding-left: 0;

            a {
                line-height: 1;
                color: $dark;
                text-decoration: none;
                font-size: 14px;
                display: block;
                padding: 10px 0;
                font-family: $headings-font-family;
                font-weight: 400;

                &:hover {
                    color: $primary;
                    padding-left: 5px;
                }
            }
        }
        

    }
}

/* Actions */
.header-actions {
    display: flex;
    justify-content: flex-end;
    & > * {
        & + * {
            margin-left: 15px;
            @media #{$large-mobile} {
                margin-left: 15px;
            }
            @media #{$small-mobile} {
                margin-left: 15px;
            }
        }
    }
    & .header-action-btn {
        line-height: 100px;

        position: relative;

        display: block;

        text-decoration: none;

        color: $dark;
        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
        &:hover {
            color: $primary;
        }

        &-cart {
            padding-right: 10px;
        }
        & i {
            font-size: 24px;
            line-height: 1;
            vertical-align: middle;
        }
        & .header-action-num {
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;

            position: absolute;
            z-index: 2;
            top: 30px;
            right: 0;

            width: 20px;
            height: 20px;

            text-align: center;

            color: $white;
            border-radius: 50%;
            background-color: $primary;
        }
    }
}

/* Sticky header */
.header-sticky {
    background: $white;

    &.sticky {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        transition: all .5s ease 0s;
        border-bottom: 1px solid rgba(0,0,0,0.075);
        animation: fadeInDown .5s ease-in-out;
    }
}
