/*----------------------------------------*/
/*  04. Hero CSS
/*----------------------------------------*/
.hero-slide-item{
    position: relative;
    display: flex;
    align-items: center;
    height: 750px;
    @media #{$desktop-device, $laptop-device} {
        height: 700px;
    }
    @media #{$tablet-device} {
        height: 600px;
    }
    @media #{$large-mobile} {
        height: 400px;
    }
    @media #{$extra-small-mobile} {
        height: 300px;
    }
}
.hero-slide-item-two{
    position: relative;
    display: flex;
    align-items: center;
    height: 560px;
    @media #{$desktop-device, $laptop-device} {
        height: 500px;
    }
    @media #{$tablet-device} {
        height: 500px;
    }
    @media #{$large-mobile} {
        height: 300px;
    }
    @media #{$extra-small-mobile} {
        height: 300px;
    }
}
.hero-slide-bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.hero-slide-content{
    position: relative;
    z-index: 99;
    & .title{
        color: $headings-color;
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 72px;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    & p {
        font-weight: 400;
        line-height: 1.16;
        color: $dark;
        margin-top: 10px;
        margin-bottom: 55px;
        display: block;
        font-size: 18px;
        font-family: $font-family-base;
        color: $headings-color;
    }

    @media #{$large-mobile} {
        & .title {
            font-size: 36px;
        }
        & p {
            margin-bottom: 30px;
        }
    }

    @media #{$extra-small-mobile} {

        & .title {
            font-size: 30px;
            margin-bottom: 10px;
        }

        & p {
            font-size: 14px;
        }
    }
}
.hero-slider{
    // Swiper Navigation
    .main-slider-nav{
        color: $dark;
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;
        font-size: 50px;
        &::after{
            display: none;
        }
        &:hover{
            color: $primary;
            transition: $transition-base;
        }
    }
    .home-slider-prev{
        left: 30px;        
        @media #{$small-mobile}{
            left: 5px;
        }
    }
    .home-slider-next{
        right: 30px;
        &::after{
            display: none;
        }
        @media #{$small-mobile}{
            right: 5px;
        }
    }
    &:hover{
        & .main-slider-nav, & .swiper-pagination{
            opacity: 1;
            visibility: visible;
        }
    }

    .home-slider-prev, .home-slider-next {
        margin-top: 0;
        transform: translateY(-50%);

        i {
            background: rgb(255, 255, 255);
            opacity: .5;
            transition: $transition-base;
        }

        &:hover {
            i {
                background: $white;
                opacity: 1;
                transition: $transition-base;
            }
        }
    }

    // Swiper Pagination

    .swiper-pagination {
        position: absolute;
        bottom: 20px;
        opacity: 0;
        visibility: hidden;
    }
}
.hero-slide-content {
    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}
.swiper-slide-active{
    & .hero-slide-content {

        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }
            &:nth-child(2) {
                animation-delay: 1.2s;
            }
            &:nth-child(3) {
                animation-delay: 1.5s;
            }
            &:nth-child(4) {
                animation-delay: 2s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
            
        }
    }
}