/*====================  
    3.4 Header Cart CSS
====================*/
/*  Cart Offcanvas Wrapper */
 .cart-offcanvas-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: $transition-base;
    transition-duration: 700ms;
    z-index: 9999;
    &.open{
        opacity: 1;
        visibility: visible;
        & .cart-offcanvas-inner {
            transform: none;
        }
    }
}

/*-- Cart Offcanvas Inner --*/
.cart-offcanvas-inner {
    width: 100%;
    height: 100%;
    width: 380px;
    background-color: $white;
    box-shadow: -1px 0px 20px -5px #aaa;
    position: relative;
    transform: translateX(calc(100% - 50px));
    transition: $transition-base;
    transition-duration: 700ms;
    margin-left: auto;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    @media #{$small-mobile} {
        width: 320px;
    }
    & .offcanvas-btn-close{
        display: flex;
        justify-content: flex-end;
        font-size: 50px;
        color: $dark;
        opacity: 1;
        padding: 20px 20px 0 20px;
        & i{
            cursor: pointer;
            transform: rotate(0deg);
            transition: $transition-base;
            &:hover{
                color: $primary;
                transition: $transition-base;
                transform: rotate(90deg);
            }
        }
    }
}

/*-- Offcanvas Cart Content --*/
.offcanvas-cart-content {
    padding: 0 20px 20px 20px;
    & .offcanvas-cart-title {
        font-size: 20px;
        font-family: $headings-font-family;
        color: $headings-color;
        font-weight: 600;
        text-align: left;
    }
}
.cart-product-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & .single-cart-product{
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 40px);
        & .cart-product-thumb{
            width: 80px;
            & a{
                display: block;
                & img {
                    width: 100%;
                    border: 1px solid #ebebeb;
                }
            }
        }
        & .cart-product-content {
            width: calc(100% - 80px);
            text-align: start;
            padding-left: 10px;
            & .title {
                font-size: 15px;
                font-weight: 600;

                & a {
                    color: $dark;
                    &:hover {
                        color: $primary;
                    }
                }
            }
            & .price {
                text-align: start;
                justify-content: flex-start;
            }
        }
    }
    & .cart-product-remove {
        width: 40px;
        & a {
            color: $dark;
            font-size: 14px;

            &:hover {
                color: $primary;
            }
        }
    }
}
.cart-product-total {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & .value {
        font-size: 20px;
        font-weight: 600;
        color: $dark;
    }
    & .price {
        font-size: 22px;
        font-weight: 600;
        color: $dark;
    }
}
